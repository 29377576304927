<template>
  <div class="container">
    <div class="stickers">
      <Sticker image="/assets/ui_elements/phoneSticker.svg" id="phoneSticker" :delay="0.15" />
      <Sticker image="/assets/ui_elements/eyesSticker.svg" id="eyesSticker" :delay="0.15" />
    </div>

    <div class="body">
      <div class="header">
        <h1>{{ $t('youve-been-invited-to-play-a-game-of') }} <strong>{{ $t('likely-story') }}</strong></h1>
      </div>
      <div class="footer">
        <Button class="primary large" @click="$emit('newGame')">{{ $t('join-the-game') }}</Button>
        <Button class="secondary large" @click="goToUrl()">{{ $t('learn-to-play') }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./components/buttons/Button";
import Sticker from "./components/stickers/Sticker";

export default {
  name: "Invited",
  components: { Button, Sticker },
  emits: ["newGame"],
  methods: {
    goToUrl: function () {
      location.href = '/onboarding'
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
}

.stickers {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 22px;
  height: calc(100% - 44px);
}

.header {
  flex-grow: 1;

  h1 {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    max-width: 10em;
    text-align: center;
    margin: 0 auto;

    strong {
      font-weight: 800;
    }
  }
}

.footer {
  flex-grow: 0;
}

#phoneSticker {
  position: absolute;
  width: 100px;
  top: 80px;
  left: -50px;
}

#eyesSticker {
  position: absolute;
  width: 100px;
  top: 10px;
  right: -30px;
}
</style>
